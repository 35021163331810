import { ComputedRef, readonly, ref, useContext } from "@nuxtjs/composition-api";
import { Customer } from "@vue-storefront/magento-types";
import { AvailablePaymentMethod, usePaymentCard, useValidator } from "~/composables";
import { Logger } from "~/helpers/logger";
import useCart from "~/modules/checkout/composables/useCart";
import { getAvailablePaymentMethodsCommand } from "~/modules/checkout/composables/usePaymentProvider/commands/getAvailablePaymentMethodsCommand";
import { getSlipPdfCommand } from "~/modules/checkout/composables/usePaymentProvider/commands/getSlipPdf";
import { setPaymentMethodOnCartCommand } from "~/modules/checkout/composables/usePaymentProvider/commands/setPaymentMethodOnCartCommand";
import BoletoService from "~/server/payments/BoletoService";
import PixService from "~/server/payments/PixService";
import { ItauBoletoResponse } from "~/server/payments/types/BoletoTypes";
import { ItauPixRequest } from "~/server/payments/types/PixTypes";
import { useCheckoutStore } from "~/stores/checkout";
import { CustomHeaders, CustomQuery } from "~/types/core";
import { RedeErrorMessages } from "../../types";
import BuildSlipBody from "./commands/slipBuilder";
import SlipTransform from "./commands/slipTransform";
import type { PaymentMethodParams, UsePaymentProviderErrors, UsePaymentProviderInterface, UsePaymentProviderSaveParams } from "./usePaymentProvider";

/**
 * Allows loading the available payment
 * methods for current cart, and selecting (saving) one of them.
 *
 * See the {@link UsePaymentProviderInterface} for a list of methods and values available in this composable.
 */
export function usePaymentProvider(): UsePaymentProviderInterface {
  const checkoutStore = useCheckoutStore();
  const context = useContext();
  const { cart } = useCart();
  const { payOrder } = usePaymentCard();
  const loading = ref(false);
  const error = ref<UsePaymentProviderErrors>({
    load: null,
    save: null,
    getSlipPdf: null,
  });

  const save = async (params: UsePaymentProviderSaveParams) => {
    Logger.debug("usePaymentProvider.save");
    let result = null;

    try {
      loading.value = true;
      const paymentMethodParams: PaymentMethodParams = {
        cart_id: cart.value.id,
        payment_method: {
          ...params.paymentMethod,
        },
        customQuery: params.customQuery,
        customHeaders: params?.customHeaders,
      };
      cart.value.selected_payment_method = {
        code: paymentMethodParams.payment_method.code,
        purchase_order_number: paymentMethodParams.payment_method.purchase_order_number,
        title: paymentMethodParams.payment_method.code,
      };

      result = await setPaymentMethodOnCartCommand.execute(context, paymentMethodParams);

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error("usePaymentProvider/save", err);
    } finally {
      loading.value = false;
    }

    Logger.debug("[Result]:", { result });
    return result;
  };

  const getPaymentMethods = (): AvailablePaymentMethod[] => {
    let paymentMethods = [
      {
        code: "jetpix",
        title: "Pix",
      },
      {
        code: "jetboleto",
        title: "Boleto",
      },
      {
        code: "jetcred",
        title: "Cartão de crédito",
      },
    ];

    if (process.env.VSF_NUXT_APP_ENV === "development") {
      paymentMethods = [
        ...paymentMethods,
        {
          code: "checkmo",
          title: "Check / Money order",
        },
      ];
    }

    return paymentMethods;
  };

  const setPaymentMethod = async (paymentMethodCode: string) => {
    const paymentMethods = getPaymentMethods();

    paymentMethods.forEach((method) => {
      if (method.code === paymentMethodCode) {
        checkoutStore.setSelectedPaymentMethod(paymentMethodCode);
      }
    });
  };

  const load = async (customQuery?: CustomQuery, customHeaders?: CustomHeaders) => {
    Logger.debug("usePaymentProvider.load");
    let result = null;

    try {
      loading.value = true;
      result = await getAvailablePaymentMethodsCommand.execute(context, cart.value.id, customQuery, customHeaders);
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error("usePaymentProvider/load", err);
    } finally {
      loading.value = false;
    }

    Logger.debug("[Result]:", { result });
    return result;
  };

  const getSlipPdf = async (slipBody: ItauBoletoResponse) => {
    Logger.debug("usePaymentProvider.getSlipPdf");
    let result = null;
    try {
      loading.value = true;
      const jetSlipBody = SlipTransform(slipBody);
      result = await getSlipPdfCommand.execute(jetSlipBody);
      error.value.getSlipPdf = null;
    } catch (err) {
      error.value.getSlipPdf = err;
      Logger.error("usePaymentProvider/getSlipPdf", err);
      return err;
    } finally {
      loading.value = false;
    }

    Logger.debug("[Result]:", { result });
    return result;
  };

  const processPayment = async (paymentCode: string, value: number, user: ComputedRef<Customer>, order_id: string = "") => {
    const { validateCPF } = useValidator();
    const tipoPessoa = validateCPF(user.value.taxvat) ? "F" : "J";
    const data = new Date();
    const dataVencimento = new Date();
    dataVencimento.setDate(data.getDate() + 2);
    let body ={}
    switch (paymentCode) {
      case "jetcred":
        const response = await payOrder(order_id);
        if (typeof response === "string" && response in RedeErrorMessages) {
          return { success: false, data: RedeErrorMessages[response] };
        }
        return { success: true, data: "" };
      case "jetpix":
        const pixBody: ItauPixRequest = {
          calendario: {
            expiracao: 1800,
          },
          valor: {
            original: value.toString(),
          },
          chave: "53700159000185",
        };
        body ={
          data: pixBody,
          amount: value,
        }
        const pixResponse = await PixService.emitirCobrancaImediata(body, order_id);
        return pixResponse;
      case "jetboleto":
        const slipBody = BuildSlipBody(user, value, tipoPessoa, data, dataVencimento, order_id);
        body = {
          data: slipBody,
          amount: value,
        }
        const boletoResponse = await BoletoService.emitirBoleto(body, order_id);
        return boletoResponse;
      default:
        return "";
    }
  };

  return {
    load,
    save,
    getSlipPdf,
    error: readonly(error),
    loading: readonly(loading),
    processPayment,
    getPaymentMethods,
    setPaymentMethod,
  };
}

export * from "./usePaymentProvider";
export default usePaymentProvider;
