

















































































import { defineComponent, useContext, useRouter } from '@nuxtjs/composition-api';
import { SfBottomNavigation, SfCircleIcon } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    SfBottomNavigation,
    SfCircleIcon,
    SvgImage,
  },
  setup() {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();

    const handleHomeClick = async () => {
      const homePath = app.localeRoute({ name: 'home' });
      await router.push(homePath);
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer' }));
      } else {
        toggleLoginModal();
      }
    };

    return {
      isAuthenticated,
      toggleWishlistSidebar,
      toggleCartSidebar,
      handleAccountClick,
      handleHomeClick,
      toggleMobileMenu,
    };
  },
});
