import type { GetProductSearchParams } from "~/modules/catalog/product/types";
import { UseContextReturn } from "~/types/core";

export const getVolumeCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const query = `
      query GetProductVolume($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            ... on SimpleProduct {
              volume_height
              volume_width
              volume_length
              weight
            }
          }
        }
      }`;

    if (!searchParams.sku) {
      throw new Error('SKU is required');
    }

    const queryVariables = {
      sku: searchParams.sku,
    };

    const { data }: any = await context.app.$vsf.$magento.api.customQuery({ query, queryVariables });

    return data?.products ?? null;
  },
};
